import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import photo from "assets/images/newsvg/user.svg";
import strings from "./lang/widget_ru";
import { logout } from "../../store/slices/authSlice";
import { ws } from "../notification/websocket/websocket";
import axios from "axios";
import { getValueFromCookie } from "../../utils/getValueFromCookie";

const UserWidget = ({ modifiers }) => {
  const [showPanel, setShowPanel] = useState(false);
  const auth = useSelector((state) => state.auth);
  const socket = ws;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const node = useRef();

  useEffect(() => {
    if (showPanel) {
      document.addEventListener("mousedown", handleClose);
    } else {
      document.removeEventListener("mousedown", handleClose);
    }
    return () => {
      document.removeEventListener("mousedown", handleClose);
    };
  }, [showPanel]);

  const handleClose = (e) => {
    if (!node.current.contains(e.target) && showPanel === true) {
      setShowPanel(false);
    }
  };

  const handleToggle = () => {
    setShowPanel((prev) => !prev);
  };

  const handleRequestMark = async (label) => {
    const token = getValueFromCookie("token");
    try {
      await axios.get(`${process.env.REACT_APP_HOST}services/event-log/?event=${label}`, token && {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      console.log(err);
    }
  }

  const handleClick = async (label) => {
    handleToggle();
    handleRequestMark(label);
  }

  return (
    <div className={`header__user ${modifiers ? modifiers : ""}`} ref={node}>
      <a className={auth?.user?.photo ? null : "-without-photo"} onClick={() => handleClick("user_avatar")}>
        <img src={auth?.user?.photo ? auth.user.photo : photo} alt="user_photo" />
      </a>
      {showPanel ? (
        <div className={`header__user__panel`}>
          <div className="header__user__name">
            {auth?.user?.first_name} {auth?.user?.last_name}
          </div>
          <div className="header__user__email">{auth?.user?.email}</div>
          <div className="header__user__product" onClick={() => {
            navigate("/dashboard/1")
            handleRequestMark("user_main_page")
          }}>
            Marketvision
          </div>
          {auth.user.tariff_type === "pro" && (
            <NavLink
              to="/payment/"
              onClick={() => handleClick("user_payment")}
              className={({ isActive }) => (isActive ? "header__user__link -active" : "header__user__link")}
            >
              {strings.payment}
            </NavLink>
          )}
          <NavLink
            to="/account/"
            onClick={() => handleClick("user_account")}
            className={({ isActive }) => (isActive ? "header__user__link -active" : "header__user__link")}
          >
            {strings.profile}
          </NavLink>
          <div className="header__user__logout">
            <button
              type="button"
              onClick={() => {
                /* Разлогиниваем пользователя при клике */
                "url" in socket && socket?.close && socket.close(3001);
                dispatch(logout());
                handleRequestMark("user_logout")
              }}
            >
              {strings.logout}
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default UserWidget;

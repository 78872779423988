import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect, useSelector } from "react-redux";

import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { YMInitializer } from "react-yandex-metrika";

import { PublicRoute } from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import {
  Report,
  DownloadReport,
  Access,
  Recovery,
  Profile as ProfileFaq,
  Main,
  AddApiKey,
  InternalMain,
  Goods,
  Good,
  Filters,
  Supplies,
  Orders,
  Finance,
  WorkWithTables,
} from "components/landing/faq";

const MainPage = lazy(() => import("components/landing/pages/MainPage/MainPage"));
const MarketEvents = lazy(() => import("components/landing/pages/MarketEvents"));
const ProductDatabase = lazy(() => import("components/landing/pages/ProductDatabase"));
const BrandsAgencies = lazy(() => import("components/landing/pages/BrandsAgencies"));
const About = lazy(() => import("components/landing/pages/About"));
const Sellers = lazy(() => import("components/landing/pages/Sellers"));
const StartSelling = lazy(() => import("components/landing/pages/StartSelling"));
const InternalAnalytics = lazy(() => import("components/landing/pages/InternalAnalytics"));
const MobileApp = lazy(() => import("components/landing/pages/MobileApp"));
const Policy = lazy(() => import("components/landing/pages/Policy"));
const Referal = lazy(() => import("components/landing/pages/Referal"));
const Offer = lazy(() => import("components/landing/pages/Offer"));
const TariffNoAccess = lazy(() => import("components/landing/pages/TariffNoAccess"));


const Login = lazy(() => import("components/auth/Login"));
const Registration = lazy(() => import("components/auth/Registration"));
const PassRecovery = lazy(() => import("components/auth/PassRecovery"));
const Success = lazy(() => import("components/auth/Success"));
const History = lazy(() => import("components/payment/History"));

const MetaHeader = lazy(() => import("components/metaHeader/MetaHeader"));
const PageNotFound = lazy(() => import("components/pages/404page"));

const EventsLazy = lazy(() => import("components/pages/Events"));
const ProfileLazy = lazy(() => import("components/profile/Profile"));
const PriceLazy = lazy(() => import("components/pages/PricesPage/Price"));
const ChooseTariffLazy = lazy(() => import("components/pages/ChooseTariff"));
const NewInternalAnalytics = lazy(() => import("components/pages/NewInternalAnalytics"));
const NewExternalAnalytics = lazy(() => import("components/pages/NewExternalAnalytics"));
const CardInfo = lazy(() => import("components/pages/CardInfo"));
const CardInfoExternal = lazy(() => import("components/newExternalAnalytics/CardInfo"));
const BrandCard = lazy(() => import("components/pages/BrandCard"));
const TableSales = lazy(() => import("components/pages/TableSales"));
const TableRefunds = lazy(() => import("components/pages/TableRefunds"));
const CategoryCard = lazy(() => import("components/pages/CategoryCard"));
const DashboardLazy = lazy(() => import("components/pages/Dashboard"));
const CategoryAnalysis = lazy(() => import("components/pages/CategoryAnalysis"));
const SeoOptimization = lazy(() => import("components/pages/SeoOptimization"));
const SupplierCard = lazy(() => import("components/pages/SupplierCard"));
const ApiKeyAdd = lazy(() => import("components/api-key/apiKeyAdd"));
const ApiItem = lazy(() => import("components/api-key/apiKeyItem/ApiItem"));
const OrdersSection = lazy(() => import("components/newInternalAnalytics/OrdersSection"));

const AppRouter = () => {
  const auth = useSelector((state) => state.auth.user);
  window.addEventListener("storage", (e) => {
    if (e.key === "user" && e.oldValue && !e.newValue) {
      window.location.reload();
    }
  });

  //Initialize Google Tag Manager
  const tagManagerArgs = {
    gtmId: "GTM-T4R6VHS",
  };
  TagManager.initialize(tagManagerArgs);

  //Initialize google analytics
  if (process.env.REACT_APP_DEV === "false") {
    ReactGA.initialize("UA-183210664-1");
  }

  return (
    <>
      <Helmet>
        <html lang="ru" />
      </Helmet>
      <MetaHeader />
      <Routes>
        <Route path="/" exact element={<PublicRoute component={MainPage}></PublicRoute>} />
        <Route path="/policy" exact element={<PublicRoute component={Policy} />} />
        <Route path="/referal" element={<PublicRoute component={Referal} />} />
        <Route path="/agreement" exact element={<PublicRoute component={Offer} />} />
        <Route path="/price" exact element={<PublicRoute component={PriceLazy} />} />
        <Route path="/login" exact element={<PublicRoute component={Login} />} />
        <Route path="/registration" exact element={<PublicRoute component={Registration} />} />
        <Route path="/success" exact element={<PublicRoute component={Success} />} />
        <Route path="/change_password" exact element={<PublicRoute component={PassRecovery} />} />
        <Route path="/docs" exact element={<PublicRoute component={Main} />} />
        <Route path="/docs/faq/report" exact element={<PublicRoute component={Report} />} />
        <Route path="/docs/faq/download" exact element={<PublicRoute component={DownloadReport} />} />
        <Route path="/docs/faq/access" exact element={<PublicRoute component={Access} />} />
        <Route path="/docs/faq/recovery" exact element={<PublicRoute component={Recovery} />} />
        <Route path="/docs/faq/profile" exact element={<PublicRoute component={ProfileFaq} />} />
        <Route path="/docs/internal/add_key" exact element={<PublicRoute component={AddApiKey} />} />
        <Route path="/docs/internal/main" exact element={<PublicRoute component={InternalMain} />} />
        <Route path="/docs/internal/goods" exact element={<PublicRoute component={Goods} />} />
        <Route path="/docs/internal/good" exact element={<PublicRoute component={Good} />} />
        <Route path="/docs/internal/filters" exact element={<PublicRoute component={Filters} />} />
        <Route path="/docs/internal/supplies" exact element={<PublicRoute component={Supplies} />} />
        <Route path="/docs/internal/orders" exact element={<PublicRoute component={Orders} />} />
        <Route path="/docs/internal/finance" exact element={<PublicRoute component={Finance} />} />
        <Route path="/docs/internal/tables" exact element={<PublicRoute component={WorkWithTables} />} />
        <Route path="/about" exact element={<PublicRoute component={About} />} />
        <Route path="/market-events" exact element={<PublicRoute component={MarketEvents} />} />
        <Route path="/product-database" exact element={<PublicRoute component={ProductDatabase} />} />
        <Route path="/internal-analytics" exact element={<PublicRoute component={InternalAnalytics} />} />
        <Route path="/mobile-app" exact element={<PublicRoute component={MobileApp} />} />
        <Route path="/brands-agencies" exact element={<PublicRoute component={BrandsAgencies} />} />
        <Route path="/sellers" exact element={<PublicRoute component={Sellers} />} />
        <Route path="/tarif-no-access" exact element={<PublicRoute component={TariffNoAccess} />} />
        <Route path="/start-selling" exact element={<PublicRoute component={StartSelling} />} />
        <Route path="dashboard/:source" exact element={<PrivateRoute component={DashboardLazy} />} />
        <Route path="account" exact element={<PrivateRoute component={ProfileLazy} />} />
        <Route path="payment" exact element={<PrivateRoute component={ChooseTariffLazy} />} />
        <Route path="payment/history" exact element={<PrivateRoute component={History} />} />
        <Route path="products/:source" exact element={<PrivateRoute component={NewExternalAnalytics} />} />
        <Route path="events" exact element={<PrivateRoute component={EventsLazy} />} />
        <Route path="categories/:source/:category_code/" exact element={<PrivateRoute component={CategoryCard} />} />
        <Route path="categories/:source/:category_code/:subject_id" exact element={<PrivateRoute component={CategoryCard} />} />
        <Route path="products/:source/:vendor_code" exact element={<PrivateRoute component={CardInfoExternal} />} />
        <Route path="brands/:source/:brand_code" exact element={<PrivateRoute component={BrandCard} />} />
        <Route path="analysis/:source" exact element={<PrivateRoute component={CategoryAnalysis} />} />
        <Route path="seo-optimization/:source" exact element={<PrivateRoute component={SeoOptimization} />} />
        <Route path="suppliers/:source/:supplier_code/:supplier_name" exact element={<PrivateRoute component={SupplierCard} />} />
        <Route path="apikey" exact element={<PrivateRoute component={ApiKeyAdd} />} />
        <Route path="apikey/:id" exact element={<PrivateRoute component={ApiItem} />} />
        <Route path="internal/main" exact element={<PrivateRoute component={NewInternalAnalytics} />} />
        <Route path="internal/goods" element={<PrivateRoute component={NewInternalAnalytics} />} />
        <Route path="internal/supply" element={<PrivateRoute component={NewInternalAnalytics} />} />
        <Route path="internal/card_info/:id/:vendor_code" exact element={<PrivateRoute component={CardInfo} />} />
        <Route path="internal/sales/:id/" exact element={<PrivateRoute component={TableSales} />} />
        <Route path="internal/sales/:id/:source" exact element={<PrivateRoute component={TableSales} />} />
        <Route path="internal/refunds/:id/" exact element={<PrivateRoute component={TableRefunds} />} />
        <Route path="internal/refunds/:id/:vendorCode" exact element={<PrivateRoute component={TableRefunds} />} />
        <Route path="internal/orders" exact element={<PrivateRoute component={OrdersSection} />} />
        <Route path='/fashion' element={ <Navigate to="/" />} />
        <Route path='/scaling' element={ <Navigate to="/" />} />
        <Route path='/start' element={ <Navigate to="/" />} />
        <Route path='/usability' element={ <Navigate to="/" />} />
        <Route path="*" element={auth ? <Navigate to="/dashboard/1" /> : <PublicRoute component={PageNotFound} />} />

        {//блок Партнеры временно скрыт с лэндингов
        
        /* <Route path="/partners" exact element={<PublicRoute component={Partners} />} />
        <Route path="/partners/*" exact element={<PublicRoute component={PartnersDetail} />} /> */}

      </Routes>
      {/*Initialize yandex metrika*/}
      {process.env.REACT_APP_DEV === "false" && (
        <YMInitializer accounts={[69472594]} options={{ webvisor: true, clickmap: true }} version="2" />
      )}
    </>
  );
};

export default connect()(AppRouter);
